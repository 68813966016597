
import { defineComponent, createVNode } from "vue";
import { Input, Button, message, Card, Modal } from "ant-design-vue";
import {
  DragOutlined,
  EditOutlined,
  DeleteOutlined,
  RightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import draggable from "vuedraggable";
import {
  categoryList,
  addCategory,
  updateCategory,
  deleteCategory,
  categoryOrder, 
  // sortCategory,
} from "@/api/terminalList.js";
import ModalDel from "./Modal.vue";
export default defineComponent({
  components: {
    Button,
    Input,
    message,
    Card,
    draggable,
    DragOutlined,
    EditOutlined,
    DeleteOutlined,
    RightOutlined,
    ModalDel,
    CheckOutlined,
    PlusOutlined,
  },
  data() {
    return {
      height: "500px",
      editCategoryCode: -1,
      drag: false, //判断是否被拖拽过
      newCategory: "",
      keepCategoryName: "",
      addNewCategory: false,
      items: [],
      activeCode: "",
      loading: false,
      contentHeight: "500px",
    };
  },
  methods: {
    //点击新增品牌
    addnew() {
      (this as any).$store.commit("changeCategoryEdit", true);
      this.addNewCategory = true;
      setTimeout(() => {
        let input = document.querySelector("input");
        if (input) {
          input.focus();
        }
      }, 0);
    },
    //失去焦点 新增品类
    blurAddNewCategory() {
      if (this.newCategory.trim().length == 0) {
        this.addNewCategory = false;
        (this as any).$store.commit("changeCategoryEdit", false);
        return;
      }
      if (this.checkCategory(this.newCategory, "addNew")) {
        return;
      }
      let sort = 1000 - this.items.length;
      addCategory({
        categoryName: this.newCategory,
        sort: sort,
      })
        .then((res) => {
          if (res.data.success) {
            message.success("新增成功");
            this.getList();
            this.addNewCategory = false;
            this.newCategory = "";
          } else {
            this.addNewCategory = false;
          }
          (this as any).$store.commit("changeCategoryEdit", false);
        })
        .catch(() => {
          this.addNewCategory = false;
          (this as any).$store.commit("changeCategoryEdit", false);
        });
    },
    //获取afterSort
    getAfterSort(array) {
      array.forEach((item, index) => {
        item.afterSort = 1000 - index;
        item.beforeSort = 1000 - index;
      });
    },
    //获取一级card内容
    getList(code = "", people = 1) {
      categoryList(code).then((res: any) => {
        this.items = res.data.data;
        (this as any).items.forEach((element) => {
          element.beforeSort = element.sort;
          element.people = people;
          element.subordinate = element.isHave;
        });
      });
    },
    //校验
    checkCategory(str, type) {
      let flag = false;
      str = str.trim();
      //编辑时通过数组去重判定是否重复
      let length1 = this.items.length;
      let item2 = this.items.map((ele: any) => {
        return ele.categoryName;
      });
      let length2 = [...new Set(item2)].length;
      if (length1 !== length2) {
        message.warning("当前品类名称已存在,不可重复使用");
        flag = true;
      }
      //新增时通过 只有一个重复判断出重复 编辑重复就是两个重复
      this.items.forEach((ele: any, i) => {
        if (ele.categoryName === str && type == "addNew") {
          message.warning("当前品类名称已存在,不可重复使用");
          flag = true;
        }
      });

      if (str.length > 50) {
        message.warning("最多不能超过50个字符");
        flag = true;
      }
      if (str.length == 0) {
        message.warning("请输入品类名称");
        flag = true;
      }

      var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
      if (!reg.test(str)) {
        message.warning("仅支持汉字、字母、数字");
        flag = true;
      }

      if (flag) {
        let input = document.querySelector("input");
        if (input) {
          input.focus();
        }
      }
      return flag;
    },
    //编辑
    edit(editCategoryCode, categoryName) {
      this.keepCategoryName = categoryName;
      this.editCategoryCode = editCategoryCode;
      setTimeout(() => {
        let input = document.querySelector("input");
        if (input) {
          input.focus();
        }
      }, 0);
    },
    editCategroy(ele) {
      if (ele.categoryName == "") {
        this.getList();
        this.editCategoryCode = -1;
        return;
      }
      if (this.keepCategoryName != ele.categoryName) {
        if (this.checkCategory(ele.categoryName, "edit")) {
          return;
        }
        updateCategory({
          categoryCode: ele.categoryCode,
          categoryName: ele.categoryName,
        }).then((res) => {
          if (res.data.success) {
            message.success("编辑品类成功");
            this.getList();
            this.editCategoryCode = -1;
          }
          // else {
          //   message.error(res.data.errMessage);
          // }
        });
      } else {
        this.editCategoryCode = -1;
      }
    },
    //删除
    ///////////////////////////////////////////////////////待修改
    del(categoryCode) {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: () => createVNode(ExclamationCircleOutlined),
        content:
          "若删除该品类，关联的单品将被划分为未分类，是否确认删除？",
        cancelText: "取消",
        okText: "确认",
        onOk() {
          deleteCategory(categoryCode).then((res: any) => {
            if (res.data.success) {
              message.success("删除品类成功");
              that.getList();
              that.editCategoryCode = -1;
            }
            //  else {
            // }
          });
        },
      });
    },
    //发送排序的请求
    sortList(e) {
      setTimeout(() => {
        (this as any).$store.commit("changeloadingStatus", false);
      }, 0);
      // return updateCategory({
      //   categoryCode: e.item._underlying_vm_.categoryCode,
      //   categoryParCode: "",
      //   categoryName: e.item._underlying_vm_.categoryName,
      //   afterSort: 1000 - e.newIndex,
      //   beforeSort: 1000 - e.oldIndex,
      // });
      return categoryOrder({
        categoryCode: e.item._underlying_vm_.categoryCode,
        afterSort: 1000 - e.newIndex,
      });
    },
    //拖拽结束触发promiseList确保顺序发送请求
    dragEnd(e) {
      // console.log(e.item._underlying_vm_);
      // console.log(e);
      if ((this as any).$store.state.categoryPromiseList.length === 0) {
        let P: any;
        P = new Promise((resolve, reject) => {
          this.sortList(e).then(() => {
            (this as any).$store.commit("categoryPromiseList_shift");
            resolve("");
          });
        });
        (this as any).$store.commit("categoryPromiseList_push", P);
      } else {
        let P: any;
        P = new Promise((resolve, reject) => {
          (this as any).$store.state.categoryPromiseList[
            (this as any).$store.state.categoryPromiseList.length - 1
          ].then(() => {
            this.sortList(e).then(() => {
              (this as any).$store.commit("categoryPromiseList_shift");
              resolve("");
            });
          });
        });
        (this as any).$store.commit("categoryPromiseList_push", P);
      }
    },
    //更改排序
    // handleSort() {
    //   this.loading = true;
    //   let array: any = [];
    //   this.items.forEach((el: any, i) => {
    //     el.sort = 1000 - i;
    //     array = [...array, { categoryCode: el.categoryCode, sort: el.sort }];
    //   });
    //   sortCategory({
    //     categoryParCode: "",
    //     sortCategory: array,
    //   }).then((res) => {
    //     if (res.data.success) {
    //       this.getList();
    //       message.success("排序成功");
    //       this.drag = false;
    //     } else {
    //       message.error("服务器开了个小差");
    //     }
    //     this.loading = false;
    //   });
    // },
    //点击
    active(code) {
      this.activeCode = code;
      (this as any).$store.commit("changeGradeCode1", code);
      (this as any).$store.state.gradeCode4 = [];
      (this as any).$store.state.gradeCode2 = [];
      (this as any).$store.state.gradeCode3 = [];
      (this as any).$store.commit("changeGradeCode2", "z");
      (this as any).$store.commit("changeGradeCode3", "z");
      (this as any).$store.commit("changeGradeCode4", "z");
    },
  },
  watch: {
    editCategoryCode(value) {
      if (value != -1) {
        (this as any).$store.commit("changeCategoryEdit", true);
      } else {
        (this as any).$store.commit("changeCategoryEdit", false);
      }
    },
  },
  mounted() {
    (this as any).$store.state.gradeCode4 = [];
    (this as any).$store.state.gradeCode2 = [];
    (this as any).$store.state.gradeCode1 = [];
    (this as any).$store.state.gradeCode3 = [];
    (this as any).$store.commit("changeGradeCode1", "z");
    (this as any).$store.commit("changeGradeCode2", "z");
    (this as any).$store.commit("changeGradeCode3", "z");
    (this as any).$store.commit("changeGradeCode4", "z");
    this.height = document.body.clientHeight - 235 + "px";
    this.contentHeight = document.body.clientHeight - 275 + "px";
    this.getList();
  },
});
