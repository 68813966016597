
import { defineComponent } from "vue";
import { Input, Button, message} from "ant-design-vue";
import {
  CloudUploadOutlined,
} from "@ant-design/icons-vue";
import DragCard from "@/components/category/DragCard.vue";
import DragCard5 from "@/components/category/DragCard5.vue";
import DragCard2 from "@/components/category/DragCard2.vue";
import DragCard3 from "@/components/category/DragCard3.vue";
import DragCard4 from "@/components/category/DragCard4.vue";
import {} from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    Image,
    Button,
    DragCard,
    DragCard2,
    DragCard3,
    DragCard4,
    DragCard5,
    CloudUploadOutlined
  },
  data() {
    return {
      windowHeight:document.body.clientHeight,
    };
  },
  methods: {
    myEvent() {
      (this as any).$refs.items1.getList();
    },
    //跳转到导入页面
    batchImport() {
        this.$router.push("/masterData/category/batchImport");
    },
  },
   
  watch: {},
  mounted() {
    (this as any).$store.commit("changeBreadcrumb", ["主数据中心", "品类管理"]);
    
  },
  created() {
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
  },
});
